<template>
  <base-modal
    :title="getText('bannerText')"
    width="900px"
    size="medium"
    @closed-modal="handleExit($event)"
  >
    <div class="income-projection">
      <p class="description" v-html="getDescription()" />
      <mds-layout-grid v-if="!isIncomeProjectionDataAvailable">
        <mds-row>
          <mds-col>
            <MdsComboBox
              v-model="selectedSalaryRange"
              :data-set="estimatedSalaries"
              placeholder="Select an Option"
              class="estimated-salary"
              label="Estimated Salary"
            />
          </mds-col>
        </mds-row>
      </mds-layout-grid>
      <div class="income-info">
        <p>{{ getText('info') }}</p>
        <p class="value">
          <strong>{{ incomeValue }}</strong>
        </p>
      </div>
      <slider :ages="ages.map((e) => +e.age)" @slider-change="handleChange" />
      <p class="disclaimer">
        {{ getText('disclaimer') }}
      </p>
      <ProjectionDisclosure show-full-text-method="expand" />
      <div class="action">
        <mds-button
          variation="secondary"
          size="large"
          @click="handleExit(false)"
        >
          {{ getText('buttonBack') }}
        </mds-button>
      </div>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from '../BaseModal';
import { MdsButton } from '@mds/button';
import MdsComboBox from '@mds/combo-box';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import Slider from './Slider';
import store from '@/simpleStore';
import ProjectionDisclosure from '../../Advice/ProjectionDisclosure';
import {
  getIncomeProjectionData,
  getEstimatedSalaryRanges,
  getRecommendedRetirementAge
} from '../../service/income-projection-static-data-service';
import newRelicTracking from '@/shared/utils/newRelicTracking';
import { mapGetters } from 'vuex';

const modalTextsLink = 'modals.incomeProjection';

export default {
  name: 'IncomeProjection',
  components: {
    BaseModal,
    MdsButton,
    MdsComboBox,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    Slider,
    ProjectionDisclosure
  },
  data() {
    const yearOfBirth = new Date(store.getDOB()).getFullYear();
    return {
      isIncomeProjectionDataAvailable:
        this.getIsIncomeProjectionDataAvailable(),
      selectedAge: undefined,
      yearOfBirth,
      incomeProjectionData: getIncomeProjectionData(yearOfBirth),
      selectedSalaryRange: []
    };
  },
  computed: {
    incomeValue() {
      if (this.ages.length === 0) {
        return '--';
      }
      const currentAge = this.ages.find((el) => +el.age === this.selectedAge);
      return (
        '$' +
        (currentAge !== undefined
          ? +currentAge.value
          : +this.ages[0].value
        ).toLocaleString('en-US')
      );
    },
    estimatedSalaries() {
      return getEstimatedSalaryRanges(
        this.incomeProjectionData,
        getRecommendedRetirementAge(this.yearOfBirth)
      );
    },
    ages() {
      if (this.isIncomeProjectionDataAvailable) {
        return this.getAges();
      }

      return this.selectedSalaryRange.length === 0
        ? []
        : this.incomeProjectionData[this.selectedSalaryRange[0]].map((e) => ({
            ...e,
            value: Math.round(e.value)
          }));
    }
  },
  mounted() {
    if (this.getIsIncomeProjectionDataAvailable() === true) {
      newRelicTracking([{ key: 'incomeProjectionDataSource', value: 'API' }]);
    } else {
      newRelicTracking([{ key: 'incomeProjectionDataSource', value: 'JSON' }]);
    }
  },
  methods: {
    ...mapGetters({
      getIsIncomeProjectionDataAvailable: 'getIsIncomeProjectionDataAvailable',
      getAges: 'getAges'
    }),
    getText(text) {
      return this.$contentTransposer(`${modalTextsLink}.${text}`);
    },
    getDescription() {
      return this.isIncomeProjectionDataAvailable
        ? this.$contentTransposer(`${modalTextsLink}.description`)
        : this.$contentTransposer(`${modalTextsLink}.salaryRangeDescription`);
    },
    handleChange(value) {
      this.selectedAge = value;
    },
    handleExit($event) {
      this.$emit('on-exit', $event);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/income-projection.scss';
@import '@/assets/css/client/ingg/income-projection.scss';
</style>
