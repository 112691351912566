<template>
  <div class="mce-donut-chart">
    <mce-hover-flag
      v-if="focusedData"
      :x="mousePosition.pageX"
      :y="mousePosition.pageY"
    >
      <mce-legend hide-top-border hide-bottom-border>
        <mce-legend-item
          :color="chartColors[mouseData._itemId]"
          :label="mouseData.label"
          :value="mouseData.formattedValue"
          key-type="donut"
        ></mce-legend-item>
      </mce-legend>
    </mce-hover-flag>
    <mce-layout
      :plot-width="plotWidth"
      :plot-height="plotHeight"
      :legend-right-width="legendRightWidth"
      class="mce-chart-layout"
    >
      <template #[legendSlotName]>
        <mce-legend hide-top-border hide-bottom-border class="mce-chart-legend">
          <mce-legend-group>
            <mce-legend-item
              v-for="row in chartLegendData"
              :key="row.id"
              :label="row.label"
              :value="row.formattedValue"
              :color="chartColors[row.id]"
              key-type="donut"
            ></mce-legend-item>
          </mce-legend-group>
        </mce-legend>
      </template>

      <mce-donut-plot
        :color="chartColors"
        :data="filteredChartData"
        :data-focused="focusedData"
        :width="plotWidth"
        :height="plotHeight"
        @mousemove-plot="onMousemove"
        @mouseleave-plot="focusedData = null"
      ></mce-donut-plot>
    </mce-layout>
  </div>
</template>

<script>
import MceDonutPlot from '@mce/donut-plot';
import MceLayout from '@mce/layout';
import MceHoverFlag from '@mce/hover-flag';
import { MceLegend, MceLegendItem, MceLegendGroup } from '@mce/legend';
import { isMobileOrTablet } from '../utils';

export default {
  name: 'MceDonutChart',
  components: {
    MceDonutPlot,
    MceLayout,
    MceHoverFlag,
    MceLegend,
    MceLegendItem,
    MceLegendGroup
  },
  props: {
    chartData: {
      type: Array,
      required: true
    },
    chartColors: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      plotWidth: 350,
      plotHeight: 200,
      legendRightWidth: 220,
      mouseData: {},
      mousePosition: {},
      focusedData: null
    };
  },
  computed: {
    chartLegendData() {
      // Show all values, including zero, in the legend
      return this.chartData.map(({ label, count }) => {
        // Remove non-alphanumeric characters to create id from label
        const id = label.replace(/[^a-zA-Z0-9]/g, '');
        const formattedValue = `${count.toFixed(2)}%`;

        // Data format for mce donut plot must include a unique 'id' and a 'value' property
        return { label, id, value: count, formattedValue };
      });
    },
    filteredChartData() {
      // Ignore zero values to avoid unwanted pie padAngle slices
      return this.chartLegendData.filter((d) => d.value > 0);
    },
    legendSlotName() {
      return isMobileOrTablet() ? 'legend-top' : 'legend-right';
    }
  },
  methods: {
    onMousemove(e) {
      const { data, position } = e;

      // Highlight bar based on mouse data's reserved `_itemId` property
      this.focusedData = [data._itemId];
      this.mouseData = data;
      this.mousePosition = position;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/mce-donut-chart.scss';
</style>
