import store from '@/simpleStore';
import { newrelicSetAttribute } from '../Utils';
import { v4 as uuidv4 } from 'uuid';

const newRelicTracking = (additionalData = []) => {
  const { cl, ra, code } = store.getQueryParams();
  let sessionUserId = sessionStorage.getItem('sessionUserId');

  if (!sessionStorage.getItem('sessionUserId')) {
    sessionUserId = uuidv4();
    sessionStorage.setItem('sessionUserId', sessionUserId);
  }

  const clientId = store.getClientId();
  const enrollmentCode =
    sessionStorage.getItem('EnrollmentCode') ||
    sessionStorage.getItem('URLEntryCode');

  const attributesList = [
    { key: 'ClientId', value: clientId },
    { key: 'SessionUserId', value: sessionUserId },
    { key: 'EnrollmentCode', value: enrollmentCode },
    { key: 'urlClientId', value: cl },
    { key: 'urlAdvisoryFirmId', value: ra },
    { key: 'urlEntryCode', value: code }
  ];
  newrelicSetAttribute(attributesList.concat(additionalData));
};

export default newRelicTracking;
