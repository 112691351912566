<template>
  <div class="charts">
    <h3 class="text" v-html="currentRiskLabel" />
    <mce-donut-chart
      :chart-data="getCurrentAdviceChartData"
      :chart-colors="chartColors"
      name="current-advice"
      class="chart"
      data-ee-test="current-advice-chart"
    />
    <h3 class="text" v-html="proposedRiskLabel" />
    <mce-donut-chart
      :chart-data="getProposedAdviceChartData"
      :chart-colors="chartColors"
      class="chart"
      name="proposed-advice"
      data-ee-test="proposed-advice-chart"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MceDonutChart from '../../Charts/MceDonutChart';
import { assetClass } from '@/constants';
import {
  VRA_MCE_DONUT_CHART_COLORS,
  DEFAULT_MCE_DONUT_CHART_COLORS
} from '../../../constants';

export default {
  name: 'EnhancementCharts',
  components: {
    MceDonutChart
  },
  props: {
    account: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapGetters({
      getShowInggVraUX: 'getShowInggVraUX'
    }),
    proposedRiskLabel() {
      const riskLabel = this.getRiskLabel(
        this.account.proposedAdviceStrategy?.riskLevel
      );

      return this.$contentTransposer('advice.riskLevel.proposed', {
        proposedRiskLabel: riskLabel
      });
    },
    currentRiskLabel() {
      const riskLevel = this.getRiskLabel(
        this.account.currentAdviceStrategy?.riskLevel
      );

      return this.$contentTransposer('advice.riskLevel.current', {
        currentRiskLabel: riskLevel
      });
    },
    getCurrentAdviceChartData() {
      return this.getChartData(
        this.account.currentAdviceStrategy?.assetMixAdvisableAccounts
      );
    },
    getProposedAdviceChartData() {
      return this.getChartData(
        this.account.proposedAdviceStrategy?.assetMixAdvisableAccounts
      );
    },
    chartColors() {
      return this.getShowInggVraUX
        ? VRA_MCE_DONUT_CHART_COLORS
        : DEFAULT_MCE_DONUT_CHART_COLORS;
    }
  },
  methods: {
    formatData(element) {
      return {
        label: element.AssetClass,
        count: +element.Weight
      };
    },
    getChartData(assetMixAdvisableAccounts) {
      const accountObj = assetMixAdvisableAccounts;
      return this.getUIAssetClassesLabel(accountObj.Asset)
        .map(this.formatData)
        .filter((asset) => asset.label !== 'Annuity');
    },
    getUIAssetClassesLabel(assetMix) {
      Object.keys(assetClass).forEach((asset, index) => {
        assetMix[index].AssetClass =
          asset === assetMix[index].AssetClass
            ? assetClass[asset]
            : assetMix[index].AssetClass;
      });
      return assetMix;
    },
    getRiskLabel(riskType) {
      const riskTypes = {
        1: 'Conservative',
        2: 'Moderately Conservative',
        3: 'Balanced',
        4: 'Moderately Aggressive',
        5: 'Aggressive'
      };

      return riskTypes[riskType] || '';
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/charts.scss';
</style>
