<template>
  <MdsSection
    :title="modalData.title"
    bold
    container
    class="advisory-agreement printable-area"
  >
    <template #mds-section-actions>
      <MdsButton
        class="print-btn print-btn-margin"
        data-ee-test="print-btn"
        variation="icon-only"
        icon="print"
        type="button"
        @click="print()"
      />
    </template>
    <div id="print" class="footer-modal-content">
      <div v-if="modalData.name === CONTACT_US">
        <ContactUs />
      </div>
      <div v-html="modalData.body"></div>
    </div>
  </MdsSection>
</template>

<script>
import { MdsButton } from '@mds/button';
import MdsSection from '@mds/section';
import ContactUs from '../layout/footer-modals/ContactUs.vue';
import { ADVISORY_AGREEMENT, CONTACT_US } from '../../constants';
import footerModalData from '../mixins/footer-modal-data';
import store from '@/simpleStore';
import { mapGetters, mapActions } from 'vuex';
import { calculateProgramFees } from '@/mappers/programFee';

export default {
  components: {
    MdsButton,
    ContactUs,
    MdsSection
  },
  mixins: [footerModalData],
  data() {
    return {
      CONTACT_US
    };
  },
  computed: {
    ...mapGetters({
      getProgramFeeVal: 'getProgramFeeVal'
    })
  },
  created() {
    const programFee = store.getProgramFees() ? calculateProgramFees() : null;
    this.setProgramFeeStatement(programFee);
    this.getContent(ADVISORY_AGREEMENT);
  },
  methods: {
    ...mapActions({
      setProgramFeeStatement: 'setProgramFeeStatement'
    }),
    print() {
      window.print();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/rm-ui-footer-modals.scss';
@import '@/assets/css/client/default/advisory-agreement.scss';
</style>
