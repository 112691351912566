<template>
  <div class="enhancements">
    <mds-layout-grid>
      <mds-row>
        <mds-col>
          <enhancement-accounts
            :accounts="accountDetails"
            class="carousel"
            @on-carousel-change="accountChange"
          />
        </mds-col>
      </mds-row>
      <mds-row>
        <mds-col>
          <h2 class="title">
            <strong>{{ $contentTransposer('advice.enhancementTitle') }}</strong>
          </h2>
        </mds-col>
      </mds-row>
      <mds-row align-horizontal="center" class="content">
        <mds-col :cols-at-m="9">
          <div class="content-wrapper">
            <div class="icon">
              <SVGImage
                :is-client-specific-s-v-g="showInggVraUX"
                path="percent-icon"
                name="percent-icon"
              />
            </div>
            <div class="information">
              <h3
                v-if="currentSavingsRate !== 0"
                v-html="currentSavingsRateDescription"
              />
              <h3
                v-if="proposedSavingsRate !== 0 && currentSavingsRate !== 0"
                v-html="proposedSavingsRateDescription"
              />
              <CustomSavingRecommendation
                v-else
                :selected-account-index="selectedAccountIndex"
                :proposed-savings-rate="proposedSavingsRate"
                :current-savings-rate="currentSavingsRate"
                :accounts="accounts"
              />
            </div>
          </div>
          <div class="content-wrapper">
            <div class="icon">
              <SVGImage
                :is-client-specific-s-v-g="showInggVraUX"
                path="cake-icon"
                name="cake-icon"
              />
            </div>
            <div class="information">
              <h3 v-html="$contentTransposer('advice.retirementAge.text')" />
              <h3 class="action">
                <mds-button
                  size="touch"
                  @click="toggleIncomeProjectionModal(true)"
                >
                  {{ $contentTransposer('advice.retirementAge.button') }}
                </mds-button>
              </h3>
            </div>
          </div>
          <div class="content-wrapper">
            <div class="icon">
              <SVGImage
                :is-client-specific-s-v-g="showInggVraUX"
                path="portfolio-icon"
                name="portfolio-icon"
              />
            </div>
            <div class="information">
              <enhancement-charts
                v-if="adviceChartData"
                :account="adviceChartData"
              />
            </div>
          </div>
        </mds-col>
      </mds-row>
    </mds-layout-grid>
    <income-projection
      v-if="showIncomeProjectionModal"
      @on-exit="toggleIncomeProjectionModal($event)"
    />
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import { MdsButton } from '@mds/button';
import EnhancementAccounts from './Accounts';
import EnhancementCharts from './Charts';
import IncomeProjection from '../../modals/IncomeProjection';
import newRelicTracking from '@/shared/utils/newRelicTracking';
import CustomSavingRecommendation from '.././CustomSavingRecommendation.vue';
import { mapGetters } from 'vuex';
import store from '@/simpleStore';

export default {
  name: 'Enhancements',
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsButton,
    EnhancementAccounts,
    EnhancementCharts,
    IncomeProjection,
    CustomSavingRecommendation
  },
  data() {
    return {
      currentSavings: null,
      proposedSavings: null,
      selectedAccountIndex: 0,
      showIncomeProjectionModal: false
    };
  },
  computed: {
    accountDetails: {
      get() {
        return this.getAccountDetails();
      },
      set() {}
    },
    accounts: {
      get() {
        return store.getAccounts();
      },
      set() {}
    },
    showInggVraUX: {
      get() {
        return this.getShowInggVraUX();
      },
      set() {}
    },
    adviceChartData() {
      return this.accountDetails[this.selectedAccountIndex];
    },
    currentSavingsRate() {
      return this.accountDetails[this.selectedAccountIndex]
        ?.currentAdviceStrategy.savingsPercentage;
    },
    currentSavingsRateDescription() {
      return this.$contentTransposer('advice.savingsRate.current', {
        currentSavingsRate: this.currentSavingsRate
      });
    },
    proposedSavingsRate() {
      return this.accountDetails[this.selectedAccountIndex]
        ?.proposedAdviceStrategy.savingsPercentage;
    },
    proposedSavingsRateDescription() {
      return this.$contentTransposer('advice.savingsRate.proposed', {
        proposedSavingsRate: this.proposedSavingsRate
      });
    }
  },
  methods: {
    ...mapGetters({
      getAccountDetails: 'getAccountDetails',
      getShowInggVraUX: 'getShowInggVraUX'
    }),
    accountChange(selectedIndex) {
      this.selectedAccountIndex = selectedIndex;
    },
    toggleIncomeProjectionModal($event) {
      if ($event) {
        newRelicTracking([{ key: 'retirementAgeModal', value: 'open' }]);
      }

      this.showIncomeProjectionModal = $event;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/enhancements.scss';
@import '@/assets/css/client/ingg/enhancements.scss';
</style>
