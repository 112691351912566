<template>
  <MdsLayoutGrid class="eligibility-form">
    <MdsRow align-horizontal="center" class="body">
      <MdsCol :cols="12" :cols-at-m="9" class="form-wrapper">
        <VerifyEligibilityForm
          v-if="showEligibilityForm"
          data-ee-test="eligibility-form"
          @toggleFormState="toggleFormState"
        />
        <Loader v-if="showLoader" data-ee-test="loader" />
        <NotEligible
          v-if="showNotEligible"
          :client-id="eligibilityObj.clientId"
          :support-number="eligibilityObj.supportNumber"
          data-ee-test="not-eligible"
          @toggleFormState="toggleFormState"
        />
      </MdsCol>
    </MdsRow>
  </MdsLayoutGrid>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import VerifyEligibilityForm from './VerifyEligibilityForm';
import NotEligible from '../ConfirmationBlock/NotEligible';
import Loader from '../ConfirmationBlock/Loader';

export default {
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    VerifyEligibilityForm,
    NotEligible,
    Loader
  },
  data() {
    return {
      eligibilityObj: {
        status: 'showForm',
        showLoader: false
      }
    };
  },
  computed: {
    showLoader() {
      return this.eligibilityObj.showLoader;
    },
    showEligibilityForm() {
      return this.eligibilityObj.status === 'showForm';
    },
    showNotEligible() {
      return this.eligibilityObj.status === 'notEligible';
    }
  },
  methods: {
    toggleFormState(formStateObj) {
      this.eligibilityObj = formStateObj;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/eligibility-form.scss';
</style>
