<template>
  <div class="slider">
    <mds-range-slider
      v-model="selectedAge"
      :decimal-points="0"
      :min="minValue"
      :max="maxValue"
      :step="0.1"
      class="range-slider"
      lower-label="Age"
      @change="handleChange"
    />
    <div class="label">
      <p
        v-for="(age, index) in ages"
        :key="`label-${index}`"
        v-html="getAgeLabel(age)"
      />
    </div>
  </div>
</template>

<script>
import MdsRangeSlider from '@mds/range-slider';
import newRelicTracking from '@/shared/utils/newRelicTracking';
import { closest } from '../../utils';

const leftArrowKeyCode = 37;
const rightArrowKeyCode = 39;

export default {
  name: 'Slider',
  components: {
    MdsRangeSlider
  },
  props: {
    ages: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedAge: 0,
      touched: false
    };
  },
  computed: {
    maxValue() {
      return Math.max(...this.ages);
    },
    minValue() {
      return Math.min(...this.ages);
    }
  },
  mounted() {
    document.addEventListener('pointerup', this.handlePointerUp);
    document.addEventListener('touchend', this.handlePointerUp);
    document.addEventListener('keydown', this.handleKeyDown);
  },
  destroyed() {
    document.removeEventListener('pointerup', this.handlePointerUp);
    document.removeEventListener('touchend', this.handlePointerUp);
    document.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    getAgeLabel(age) {
      return this.$contentTransposer('modals.incomeProjection.label', { age });
    },
    handleChange() {
      if (!this.touched) {
        newRelicTracking([{ key: 'retirementAgeModal', value: 'touch' }]);
        this.touched = true;
      }
    },
    handlePointerUp() {
      if (this.selectedAge) {
        this.applyChanges(closest(this.selectedAge, this.ages));
      }
    },
    handleKeyDown(e) {
      if (e.keyCode === leftArrowKeyCode) {
        this.applyChanges(
          this.ages[this.ages.indexOf(Math.round(this.selectedAge)) - 1]
        );
      }
      if (e.keyCode === rightArrowKeyCode) {
        this.applyChanges(
          this.ages[this.ages.indexOf(Math.floor(this.selectedAge)) + 1]
        );
      }
    },
    applyChanges(value) {
      this.selectedAge = value;
      this.$emit('slider-change', this.selectedAge);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/slider.scss';
</style>
