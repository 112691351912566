<template>
  <EeContentWrapper>
    <AdviceIntro />
    <ManagedAccounts />
    <FloatingButton
      v-if="showFloatingButton"
      ref="triggerButton"
      :handle-click="handleFloatingBtn"
    />
    <UserEngagementModal
      :show-modal="showModal"
      :contact-number="productSupportNumber"
      :title="getIdleModalContent('title')"
      :sub-title="getIdleModalContent('subTitle')"
      :top-button-text="getIdleModalContent('topBtnText')"
      :bottom-button-text="getIdleModalContent('bottomBtnText')"
      modal-name="Idle Timeout Modal"
      @user-engagement-modal-dismissed="closeModal"
      @track-button-click="handleTrackButtonClick"
    />
    <UserEngagementModal
      :show-modal="showExitModal"
      :contact-number="productSupportNumber"
      :title="getExitModalContent('title')"
      :sub-title="getExitModalContent('subTitle')"
      :top-button-text="getExitModalContent('topBtnText')"
      :bottom-button-text="getExitModalContent('bottomBtnText')"
      modal-name="Exit Intent Modal"
      @user-engagement-modal-dismissed="closeExitModal"
      @track-button-click="handleTrackButtonClick"
    />
  </EeContentWrapper>
</template>

<script>
import store from '@/simpleStore';
import EeContentWrapper from './layout/ee-content-wrapper/';
import AdviceIntro from './AdviceIntro';
import ManagedAccounts from './ManagedAccounts';
import FloatingButton from './Common/FloatingButton';
import UserEngagementModal from '@/components/modals/UserEngagementModal';
import { mapActions, mapGetters } from 'vuex';
import { createIdleActivityMonitor } from '@/shared/utils/idleActivityMonitor';
import { CONTACT_INFO, TIMERS } from '../constants';
import apiService from './service/api-service';
import { trackButtonClick } from '@/shared/utils/userEngagementModalTracking';

export default {
  components: {
    EeContentWrapper,
    AdviceIntro,
    ManagedAccounts,
    FloatingButton,
    UserEngagementModal
  },
  data() {
    return {
      showFloatingButton: true,
      showModal: false,
      showExitModal: false,
      productSupportNumber: null,
      idleTime: TIMERS.IDLE_TIME_LANDING,
      pageName: 'Landing'
    };
  },

  created() {
    localStorage.setItem('showDefaultLandingLinks', `${!store.isAMA()}`);
    this.setDefaultHeroTitle();
  },
  mounted() {
    this.fetchContactInfo();

    const { showExitModal, showIdleModal } = this.getClientCustomizations();
    this.showFloatingButton = showIdleModal;
    if (showIdleModal) {
      const idleTimer = createIdleActivityMonitor(
        this.idleTime,
        this.openModal
      );
      idleTimer.startTimer();
    }
    if (showExitModal) {
      window.addEventListener('beforeunload', this.handleExitModal);
    }
  },
  beforeDestroy() {
    if (this.idleTimer) {
      this.idleTimer.stopTimer();
    }
    window.removeEventListener('beforeunload', this.handleExitModal);
  },
  methods: {
    ...mapGetters({
      getClientCustomizations: 'getClientCustomizations'
    }),
    ...mapActions({
      setHeroText: 'setHeroText'
    }),
    setDefaultHeroTitle() {
      const heroText = this.$contentTransposer('hero.title');

      this.setHeroText(heroText);
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;

      // Ensure the button loses focus
      const buttonElement =
        this.$refs.triggerButton.$el.querySelector('button');
      if (buttonElement) {
        buttonElement.blur();
      }
    },
    closeExitModal() {
      this.showExitModal = false;
    },
    async fetchContactInfo() {
      try {
        const { cl } = this.$route.query;
        const clientId = cl ? cl.toUpperCase() : null;
        const baseUri = clientId ? `${CONTACT_INFO}/${clientId}` : CONTACT_INFO;
        const responseData = await apiService.getAPIRequest(baseUri);

        this.productSupportNumber =
          responseData?.data?.ProductSupportNumber ?? null;
      } catch (error) {
        this.productSupportNumber = null;
      } finally {
        sessionStorage.setItem(
          'ProductSupportNumber',
          this.productSupportNumber
        );
      }
    },
    getIdleModalContent(key) {
      return this.$contentTransposer(
        `modals.userEngagement.landing.idleModalContent.${key}`
      );
    },
    getExitModalContent(key) {
      return this.$contentTransposer(
        `modals.userEngagement.landing.exitModalContent.${key}`
      );
    },
    handleExitModal(event) {
      event.preventDefault();
      this.showExitModal = true;
    },
    handleTrackButtonClick(buttonId, modalName) {
      trackButtonClick(buttonId, modalName, this.pageName);
    },
    handleFloatingBtn() {
      this.openModal();
      this.handleTrackButtonClick('Support Call Icon', 'Idle Timeout Modal');
    }
  }
};
</script>
