<template>
  <mds-modal
    :action-required="actionRequired"
    :aria-label="title"
    :width="width"
    :size="size"
    class="base-modal"
    title=""
    visible
    @mds-modal-dismissed="closeModal($event)"
  >
    <header class="header">
      <SVGImage
        :path="getLogoPath()"
        :name="getLogoName()"
        :is-logo="true"
        width="359px"
        height="51px"
      />
    </header>
    <section class="banner">
      <h2>{{ title }}</h2>
    </section>
    <section class="content">
      <slot></slot>
    </section>
  </mds-modal>
</template>

<script>
import MdsModal from '@mds/modal';
import store from '@/simpleStore';

export default {
  name: 'BaseModal',
  components: {
    MdsModal
  },
  props: {
    actionRequired: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '600px'
    },
    size: {
      type: String,
      default: 'small'
    }
  },
  methods: {
    closeModal($event) {
      this.$emit('closed-modal', $event);
    },
    getLogoSuffix() {
      let logoSuffix = 'rm-logo';
      if (store.getCustomizationId()) {
        logoSuffix = `RM_${store.getCustomizationId()}`;
      }
      if (store.isAMA()) {
        logoSuffix = store.getAdvisoryFirmId();
      }
      if (store.getVersion()) {
        logoSuffix = `${store.getCustomizationId()}_${store.getVersion()}`;
      }
      return logoSuffix;
    },
    getLogoName() {
      return `logo-${this.getLogoSuffix()}`;
    },
    getLogoPath() {
      return `logos/${this.getLogoSuffix()}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/base-modal.scss';
@import '@/assets/css/client/ingg/base-modal.scss';
</style>
