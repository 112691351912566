<template>
  <div class="advice">
    <EeContentWrapper has-special-offer>
      <retirement-income
        :is-button-disabled="isButtonDisabled"
        @confirm-enrollment="confirmEnrollment"
      />
      <card-wrapper>
        <special-offer />
        <enhancements />
      </card-wrapper>
      <strategy-details class="strategy" />
      <p class="cta">
        <MdsButton
          :disabled="isButtonDisabled"
          variation="primary"
          size="touch"
          data-ee-test="advice-enrollment-button"
          @click="confirmEnrollment('Bottom')"
        >
          {{ $contentTransposer('button.enrollNow') }}
        </MdsButton>
      </p>
      <FloatingButton
        v-if="showFloatingButton"
        ref="triggerButton"
        :handle-click="handleFloatingBtn"
      />
      <UserEngagementModal
        :show-modal="showIdleModal"
        :contact-number="productSupportNumber"
        :title="getIdleModalContent('title')"
        :sub-title="getIdleModalContent('subTitle')"
        :top-button-text="getIdleModalContent('topBtnText')"
        :bottom-button-text="getIdleModalContent('bottomBtnText')"
        modal-name="Idle Timeout Modal"
        @user-engagement-modal-dismissed="closeIdleModal"
        @track-button-click="handleTrackButtonClick"
      />
      <UserEngagementModal
        :show-modal="showExitModal"
        :contact-number="productSupportNumber"
        :title="getExitModalContent('title')"
        :sub-title="getExitModalContent('subTitle')"
        :top-button-text="getExitModalContent('topBtnText')"
        :bottom-button-text="getExitModalContent('bottomBtnText')"
        modal-name="Exit Intent Modal"
        @user-engagement-modal-dismissed="closeExitModal"
        @track-button-click="handleTrackButtonClick"
      />
    </EeContentWrapper>
    <ConfirmEnrollmentModal
      v-if="showConfirmEnrollmentModal"
      @closed-modal="closeModal($event)"
      @confirm="enrollParticipant"
    />
  </div>
</template>

<script>
import store from '@/simpleStore';
import { mapGetters, mapActions } from 'vuex';
import { MdsButton } from '@mds/button';
import ConfirmEnrollmentModal from '@/components/modals/ConfirmEnrollmentModal';
import CardWrapper from './CardWrapper';
import RetirementIncome from './RetirementIncome';
import SpecialOffer from './SpecialOffer';
import Enhancements from './Enhancements';
import EeContentWrapper from '../layout/ee-content-wrapper/';
import StrategyDetails from './StrategyDetails';
import FloatingButton from '../Common/FloatingButton';
import UserEngagementModal from '@/components/modals/UserEngagementModal';
import newRelicTracking from '@/shared/utils/newRelicTracking';
import enrollPpt from './utils/enrollPpt';

import {
  epTracking,
  epEnrollment
} from '../../shared/utils/engagementPlatform';
import { PAGE_ID, TIMERS } from '../../constants';
import { createIdleActivityMonitor } from '@/shared/utils/idleActivityMonitor';
import { trackButtonClick } from '@/shared/utils/userEngagementModalTracking';

export default {
  components: {
    EeContentWrapper,
    MdsButton,
    CardWrapper,
    RetirementIncome,
    SpecialOffer,
    Enhancements,
    StrategyDetails,
    ConfirmEnrollmentModal,
    FloatingButton,
    UserEngagementModal
  },
  data() {
    return {
      showConfirmEnrollmentModal: false,
      showFloatingButton: true,
      showIdleModal: false,
      showExitModal: false,
      productSupportNumber: null,
      idleTime: TIMERS.IDLE_TIME_ADVICE,
      pageName: 'Advice'
    };
  },
  computed: {
    ...mapGetters({
      getIsEnrollButtonDisable: 'getIsEnrollButtonDisable'
    }),
    isButtonDisabled() {
      return this.getIsEnrollButtonDisable;
    }
  },
  created() {
    const proposalAdvice = store.getProposalAdvice();
    this.setProposalAdvice(proposalAdvice);
    this.setIncomeProjection(proposalAdvice);
    localStorage.setItem('showDefaultLandingLinks', false);
    this.setHeroTitle();
  },
  mounted() {
    newRelicTracking();
    if (
      window &&
      (window.location.origin.includes('-qa.') ||
        window.location.origin.includes('//localhost:'))
    ) {
      epTracking(
        store.getParticipantId(),
        PAGE_ID.ADVICE,
        sessionStorage.getItem('sessionUserId')
      );
    }

    const contactNumber = sessionStorage.getItem('ProductSupportNumber');
    this.productSupportNumber = contactNumber || null;

    const { showExitModal, showIdleModal } = this.getClientCustomizations();
    this.showFloatingButton = showIdleModal;
    if (showIdleModal) {
      const idleTimer = createIdleActivityMonitor(
        this.idleTime,
        this.openIdleModal
      );
      idleTimer.startTimer();
    }
    if (showExitModal) {
      window.addEventListener('beforeunload', this.handleExitModal);
    }
  },
  beforeDestroy() {
    if (this.idleTimer) {
      this.idleTimer.stopTimer();
    }
    window.removeEventListener('beforeunload', this.handleExitModal);
  },
  methods: {
    ...mapGetters({
      getClientCustomizations: 'getClientCustomizations'
    }),
    ...mapActions({
      setHeroText: 'setHeroText',
      setShowLoader: 'setShowLoader',
      setProposalAdvice: 'setProposalAdvice',
      setIncomeProjection: 'setIncomeProjection'
    }),
    setHeroTitle() {
      const heroText = this.$contentTransposer('advice.heroTitle');

      this.setHeroText(heroText);
    },
    closeModal($event) {
      this.showConfirmEnrollmentModal = $event;
    },
    confirmEnrollment(btnId) {
      newRelicTracking([{ key: 'enrollNowButton', value: btnId }]);
      this.showConfirmEnrollmentModal = true;
    },
    async enrollParticipant() {
      this.setShowLoader(true);
      this.closeModal(false);

      const apiResponse = await enrollPpt();
      if (apiResponse.status === 'error') {
        this.$router.push({
          path: '/errorPage',
          query: store.getQueryParams()
        });
        this.setShowLoader(false);
        return;
      }

      if (
        window &&
        (window.location.origin.includes('-qa.') ||
          window.location.origin.includes('//localhost:'))
      ) {
        epEnrollment(
          store.getParticipantId(),
          sessionStorage.getItem('sessionUserId')
        );
      }

      this.setShowLoader(false);

      this.$router.push({
        path: '/enrollment',
        query: store.getQueryParams()
      });
    },
    openIdleModal() {
      this.showIdleModal = true;
    },
    closeIdleModal() {
      this.showIdleModal = false;

      // Ensure the button loses focus
      const buttonElement =
        this.$refs.triggerButton.$el.querySelector('button');
      if (buttonElement) {
        buttonElement.blur();
      }
    },
    getIdleModalContent(key) {
      return this.$contentTransposer(
        `modals.userEngagement.advice.idleModalContent.${key}`
      );
    },
    closeExitModal() {
      this.showExitModal = false;
    },
    getExitModalContent(key) {
      return this.$contentTransposer(
        `modals.userEngagement.advice.exitModalContent.${key}`
      );
    },
    handleExitModal(event) {
      event.preventDefault();
      this.showExitModal = true;
    },
    handleTrackButtonClick(buttonId, modalName) {
      trackButtonClick(buttonId, modalName, this.pageName);
    },
    handleFloatingBtn() {
      this.openIdleModal();
      this.handleTrackButtonClick('Support Call Icon', 'Idle Timeout Modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/advice.scss';
</style>
