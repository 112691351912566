<template>
  <mds-modal
    v-model="showModalFlag"
    aria-labelledby="client-content-modal"
    width="600px"
    class="ee-modal client-content-modal printable-area"
    @mds-modal-dismissed="closeModal($event)"
  >
    <mds-section border-position="bottom" bold>
      <template #mds-section-title>
        <span id="client-content-modal-title">{{ content.title }}</span>
      </template>
      <template #mds-section-actions>
        <MdsButton
          class="print-btn print-btn-margin"
          data-ee-test="print-btn"
          variation="icon-only"
          icon="print"
          type="button"
          @click="print()"
        />
        <MdsButton
          data-ee-test="close-modal-btn"
          variation="icon-only"
          icon="remove"
          type="button"
          @click="closeModal(false)"
        />
      </template>
      <div id="print" class="footer-modal-content">
        <div v-if="content.name === CONTACT_US">
          <ContactUs />
        </div>
        <div v-html="content.body"></div>
      </div>
    </mds-section>
  </mds-modal>
</template>

<script>
import MdsModal from '@mds/modal';
import MdsSection from '@mds/section';
import { MdsButton } from '@mds/button';
import ContactUs from './ContactUs';
import { cloneDeep } from 'lodash';
import { CONTACT_US } from '../../../constants';

export default {
  components: {
    MdsModal,
    MdsSection,
    MdsButton,
    ContactUs
  },
  props: {
    content: {
      type: Object,
      required: true
    },
    model: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      toggle: false,
      showModalFlag: cloneDeep(this.model),
      CONTACT_US
    };
  },
  methods: {
    closeModal($event) {
      this.$emit('footer-modal-dismissed', $event);
    },
    print() {
      window.print();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/rm-ui-footer-modals.scss';
@import '@/assets/css/client/default/client-content-modal.scss';
</style>
