<template>
  <div class="account-details" data-ee-test="account-details">
    <h3
      data-ee-test="account-details-heading"
      class="head"
      v-html="$contentTransposer('enroll.accountDetails.header')"
    />
    <h4
      data-ee-test="account-details-text"
      v-html="
        $contentTransposer('enroll.accountDetails.text', {
          providerDisplayName: model.providerDisplayName
        })
      "
    />
    <div
      v-for="(planSavingsList, index) in filteredPlanSavingsLists"
      :key="index"
      :data-ee-test="`plan-contaiiner-${index}`"
      class="plan-container"
    >
      <h5 :data-ee-test="`plan-heading-${index}`">
        <strong>{{ planSavingsList.planName }}</strong>
      </h5>
      <ul>
        <li
          v-for="(msg, idx) in planSavingsList.savingsMessages"
          :key="idx"
          :data-ee-test="`plan-savings-msg-list-${idx}`"
        >
          <span class="bullet">{{ idx + 1 }}</span>
          <span class="plan-msg" v-html="msg.savingMsg" />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import store from '@/simpleStore';
import { filter } from 'lodash';
import { CONTRIBUTION_TYPES_INFO } from '../../constants';
import { mapGetters } from 'vuex';
export default {
  props: {
    isSameSavingsRate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      model: {
        providerDisplayName: null,
        planSavingsComponentsLists: []
      }
    };
  },
  computed: {
    currentAdviceStrategy() {
      return this.getProposalAdvice().CurrentAdviceStrategy;
    },
    proposedAdviceStrategy() {
      return this.getProposalAdvice().ProposedAdviceStrategy;
    },
    filteredPlanSavingsLists() {
      return this.model.planSavingsComponentsLists.filter(
        (planSavingsList) => planSavingsList.savingsMessages.length > 0
      );
    }
  },
  created() {
    const { DisplayName } = store.getProviderContactsInfo();
    this.model.providerDisplayName = DisplayName;
    this.setPlanSavingsComponentsList();
    if (!store.getContributionChangesFlag() && !this.isSameSavingsRate) {
      this.setContriMsg();
    }
  },
  methods: {
    ...mapGetters({
      getProposalAdvice: 'getProposalAdvice'
    }),
    setContriMsg() {
      this.model.planSavingsComponentsLists.forEach(
        (planSavingsComponentsList) => {
          planSavingsComponentsList.savingsMessages = [];
          CONTRIBUTION_TYPES_INFO.forEach((contri) => {
            const current = filter(
              planSavingsComponentsList.currentSavingsComponent,
              {
                Type: contri.type,
                IsRoth: contri.IsRoth
              }
            )[0];
            const proposed = filter(
              planSavingsComponentsList.proposedSavingsComponent,
              {
                Type: contri.type,
                IsRoth: contri.IsRoth
              }
            )[0];

            if (current.Value.toFixed(2) !== proposed.Value.toFixed(2)) {
              planSavingsComponentsList.savingsMessages.push({
                savingMsg: `Save <strong>${
                  Math.round(proposed.Value * 100) / 100
                }%</strong> instead of ${
                  Math.round(current.Value * 100) / 100
                }% in your ${contri.messageText} account.`
              });
            }
          });
        }
      );
    },
    setPlanSavingsComponentsList() {
      store.getPlanInfo().forEach((plan) => {
        if (!plan.EnableContributionChanges) {
          this.model.planSavingsComponentsLists.push({
            accountId: plan.AccountId,
            planName: plan.PlanName
          });
        }
      });
      this.currentAdviceStrategy.SavingsRateDetail.forEach(
        (currentSavingsRate) => {
          this.updatePlanSavingsComponentsLists(currentSavingsRate, 'current');
        }
      );
      this.proposedAdviceStrategy.SavingsRateDetail.forEach(
        (proposedSavingsRate) => {
          this.updatePlanSavingsComponentsLists(
            proposedSavingsRate,
            'proposed'
          );
        }
      );
    },
    updatePlanSavingsComponentsLists(savingsRate, savingsRateType) {
      const acctIndex = this.model.planSavingsComponentsLists
        .map((planSavingsComponentsList) => planSavingsComponentsList.accountId)
        .indexOf(savingsRate.Account);
      if (acctIndex >= 0) {
        Object.assign(this.model.planSavingsComponentsLists[acctIndex], {
          ...(savingsRateType === 'current' && {
            currentSavingsComponent: savingsRate.SavingsComponent
          }),
          ...(savingsRateType === 'proposed' && {
            proposedSavingsComponent: savingsRate.SavingsComponent
          })
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/client/default/account-details.scss';
@import '@/assets/css/client/ingg/account-details.scss';
</style>
