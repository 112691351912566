export default {
  advisorFirmInfo: {
    advisorFirmName: 'Prime Capital Investment Advisors, LLC'
  },
  footer: {
    footerLinks: {
      privacyPolicy: {
        url: '/Merged_docs/privacy_policy/QPA_DMP_privacy_policy_merge.pdf'
      },
      firmBrochure: {
        url: '/Merged_docs/firm_brochure/QPA_DMP_firm_brochure_merge.pdf'
      },
      relationshipSummary: {
        url: '/Merged_docs/relationship_summary/QPA_DMP_relationship_summary_merge.pdf'
      },
      conversationStarters: {
        url: '/Merged_docs/conversation_starters/QPA_DMP_conversation_starters_merge.pdf'
      }
    }
  }
};
