<template>
  <div class="not-eligible">
    <StatusCardContainer>
      <SVGImage
        path="ee-confirmation-x-icon"
        name="not-eligible-icon"
        class="icon"
      />
      <div v-if="isClientIdBlocked" data-ee-test="blocked-client-container">
        <p
          class="text"
          data-ee-test="block-participant-text"
          v-html="
            $contentTransposer('landing.statusText.blockSGSTParticipantText')
          "
        />
      </div>
      <div v-else data-ee-test="error-message-container">
        <h3 class="ee__section-heading" data-ee-test="error-message-header">
          {{ $contentTransposer(`${errorTextPath}.header`) }}
        </h3>
        <div
          v-if="supportNumber"
          data-ee-test="error-message-text"
          class="text"
          v-html="
            $contentTransposer(`${errorTextPath}.textWithPhone`, {
              supportNumber
            })
          "
        />
        <div
          v-else
          data-ee-test="error-message-text"
          class="text"
          v-html="$contentTransposer(`${errorTextPath}.text`)"
        />
        <MdsButton
          class="button"
          size="touch"
          variation="primary"
          data-ee-test="try-again-button"
          @click="tryAgain"
        >
          {{ $contentTransposer('button.tryAgain') }}
        </MdsButton>
      </div>
    </StatusCardContainer>
  </div>
</template>

<script>
import { MdsButton } from '@mds/button';
import StatusCardContainer from './StatusCardContainer';
import { BLOCK_CLIENTS } from '../../../constants';

export default {
  components: {
    MdsButton,
    StatusCardContainer
  },
  props: {
    clientId: {
      type: String,
      required: false,
      default: null
    },
    supportNumber: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      eligibilityObj: {
        status: null,
        showLoader: false,
        supportNumber: null
      },
      errorTextPath: 'landing.statusText.inCorrectFieldValues'
    };
  },
  computed: {
    isClientIdBlocked() {
      return BLOCK_CLIENTS.includes(this.clientId);
    }
  },
  methods: {
    tryAgain() {
      this.eligibilityObj.status = 'showForm';
      this.$emit('toggleFormState', this.eligibilityObj);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/not-eligible.scss';
</style>
