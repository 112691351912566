<template>
  <div>
    <p v-if="!doShowFullText" class="ee__body-text--s">
      {{ disclosureShortText }}
      <a v-if="!isVersion" class="ee-link" @click="handleReadMore">READ MORE</a>
    </p>
    <div v-else v-html="disclosureFullText"></div>
  </div>
</template>

<script>
import store from '@/simpleStore';
export default {
  name: 'ProjectionDisclosure',
  props: {
    showFullTextMethod: {
      type: String,
      default: 'expand',
      validator: (value) => ['expand', 'scrollTo'].includes(value)
    },
    showFullText: { type: Boolean, default: false }
  },
  data() {
    return {
      doShowFullText: this.showFullText
    };
  },
  computed: {
    disclosureShortText() {
      return this.$contentTransposer('advice.projectionDisclosure.shortText');
    },
    disclosureFullText() {
      return this.$contentTransposer('advice.projectionDisclosure.fullText');
    },
    isVersion() {
      return store.getVersion();
    }
  },
  methods: {
    handleReadMore() {
      if (this.showFullTextMethod === 'expand') {
        this.doShowFullText = true;
      }
      if (this.showFullTextMethod === 'scrollTo') {
        document.getElementById('projection-disclosure').scrollIntoView();
      }
    }
  }
};
</script>
