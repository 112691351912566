<template>
  <div class="contact-us">
    <div v-if="getIsAMA" class="support-modal" data-ee-test="support-modal">
      <p class="subheader-text">
        {{
          $contentTransposer('footer.contact.content', {
            planProvider: displayName
          })
        }}
      </p>
      <MdsLayoutGrid gutters="none">
        <MdsRow
          v-for="(content, idx) in getSupportModalContent"
          :key="idx"
          :data-ee-test="`support-modal__contact-section-${idx}`"
          class="contact-section"
        >
          <MdsCol :cols="12" :cols-at-m="7">
            <h6 :data-ee-test="`contact-us-title-${idx}`" class="title">
              {{ content.title }}
            </h6>
            <p
              :data-ee-test="`contact-us-description-${idx}`"
              v-html="content.description"
            />
          </MdsCol>
          <MdsCol :cols="12" :cols-at-m="5">
            <div class="contact-box">
              <p :data-ee-test="`contact-box-heading-${idx}`" class="heading">
                {{ content.contactHeading }}
              </p>
              <p class="paragraph">
                <a
                  :href="`tel:${content.phoneNumber}`"
                  :data-ee-test="`contact-box-phone-${idx}`"
                  class="phone"
                  >{{ content.phoneNumber }}</a
                >
              </p>
            </div>
          </MdsCol>
        </MdsRow>
      </MdsLayoutGrid>
    </div>
    <div v-else>
      <a
        v-if="email"
        :href="`mailto:${email}`"
        class="ee-link"
        data-ee-test="contact-us-email"
        >{{ email }}</a
      >
      <div class="paragraph-block" data-ee-test="contact-us-phone">
        {{
          $contentTransposer('footer.contact.content', {
            planProvider: displayName,
            phoneNumber: phoneNumber
          })
        }}
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/simpleStore';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import footerModalData from '../../mixins/footer-modal-data';

export default {
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol
  },
  mixins: [footerModalData],
  data() {
    return {
      email: null,
      phoneNumber: null,
      productSupportAMANumber: null,
      displayName: null
    };
  },
  computed: {
    getProviderContactsInfo() {
      return store.getProviderContactsInfo();
    },
    getSupportModalContent() {
      const modalContent = [];
      const { AdvisorSupportContactInfo } = store.getPlanInfo()[0];
      const phone = this.productSupportAMANumber || this.phoneNumber;
      const path = 'footer.contact';
      if (AdvisorSupportContactInfo.FirmSupportPhoneNumber) {
        modalContent.push({
          title: this.$contentTransposer(`${path}.riaTitle`),
          description: this.$contentTransposer(`${path}.riaDescription`),
          contactHeading: AdvisorSupportContactInfo.FirmName,
          phoneNumber: AdvisorSupportContactInfo.FirmSupportPhoneNumber
        });
      }
      if (phone) {
        modalContent.push({
          title: this.$contentTransposer(`${path}.providerTitle`),
          description: this.$contentTransposer(`${path}.providerDescription`, {
            planProvider: this.displayName
          }),
          contactHeading: this.displayName,
          phoneNumber: phone
        });
      }
      return modalContent;
    }
  },
  created() {
    const {
      ProductSupportEmail,
      ProductSupportNumber,
      ProductSupportAMANumber,
      DisplayName
    } = this.getProviderContactsInfo;
    this.email = ProductSupportEmail;
    this.phoneNumber = ProductSupportNumber;
    this.productSupportAMANumber = ProductSupportAMANumber;
    this.displayName = DisplayName;
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/client/default/contact-us.scss';
</style>
