<template>
  <div class="floating-button">
    <mds-button class="float-action-button" @click="handleClick">
      <img
        data-ee-test="ee-contact-logo"
        src="@/assets/images/logos/contact-logo.svg"
        alt="contact-logo"
        class="contact-logo-icon"
        variation="icon-only"
      />
    </mds-button>
  </div>
</template>

<script>
import { MdsButton } from '@mds/button';
export default {
  name: 'FloatingButton',
  components: {
    MdsButton
  },
  props: {
    handleClick: {
      type: Function,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/floating-button.scss';
@import '@/assets/css/client/ingg/floating-button.scss';
</style>
