<template>
  <base-modal
    :title="bannerText"
    class="confirm-enrollment-modal"
    @closed-modal="closeModal($event)"
  >
    <div class="modal-content">
      <h2 class="title">
        <strong>{{ title }}</strong>
      </h2>
      <p class="description">{{ description }}</p>
      <div class="acknowledge">
        <mds-checkbox value="accept" @change="onCheckAcknowledge">
          <span v-html="acknowledge" />
        </mds-checkbox>
      </div>
      <div class="cta">
        <mds-button
          variation="secondary"
          size="large"
          @click="closeModal(false)"
        >
          {{ buttonBack }}
        </mds-button>
        <mds-button
          :disabled="!isAcknowledged"
          variation="primary"
          size="large"
          @click="confirmEnrollment"
        >
          {{ buttonConfirm }}
        </mds-button>
      </div>
    </div>
  </base-modal>
</template>

<script>
import BaseModal from '../BaseModal';
import { MdsButton } from '@mds/button';
import MdsCheckbox from '@mds/checkbox';
import store from '@/simpleStore';

const modalTextsLink = 'modals.confirmEnrollment';

export default {
  name: 'ConfirmEnrollmentModal',
  components: {
    BaseModal,
    MdsButton,
    MdsCheckbox
  },
  data() {
    return {
      bannerText: this.$contentTransposer(`${modalTextsLink}.bannerText`),
      buttonBack: this.$contentTransposer(`${modalTextsLink}.buttonBack`),
      buttonConfirm: this.$contentTransposer(`${modalTextsLink}.buttonConfirm`),
      description: this.$contentTransposer(`${modalTextsLink}.description`),
      isAcknowledged: false,
      title: this.$contentTransposer(`${modalTextsLink}.title`)
    };
  },
  computed: {
    acknowledge() {
      if (store.getVersion()) {
        return this.$contentTransposer(`${modalTextsLink}.acknowledge`, {
          privacyPolicyLink: this.getDocumentUrl('privacyPolicy'),
          privacyPolicyName: this.getDocumentName('privacyPolicy'),
          disclosureStatementLink: this.getDocumentUrl('disclosureStatement'),
          disclosureStatementName: this.getDocumentName('disclosureStatement'),
          advisoryServicesAgreementLink: this.getDocumentUrl(
            'advisoryServicesAgreement'
          ),
          advisoryServicesAgreementName: this.getDocumentName(
            'advisoryServicesAgreement'
          )
        });
      } else {
        return this.$contentTransposer(`${modalTextsLink}.acknowledge`, {
          firmBrochureLink: this.getDocumentUrl('firmBrochure'),
          firmBrochureName: this.getDocumentName('firmBrochure'),
          relationshipSummaryLink: this.getDocumentUrl('relationshipSummary'),
          relationshipSummaryName: this.getDocumentName('relationshipSummary'),
          advisoryAgreementLink: this.getDocumentUrl('advisoryAgreement'),
          advisoryAgreementName: this.getDocumentName('advisoryAgreement')
        });
      }
    }
  },
  methods: {
    closeModal($event) {
      this.$emit('closed-modal', $event);
    },
    confirmEnrollment() {
      this.$emit('confirm');
    },
    getDocumentUrl(document) {
      if (document === 'advisoryAgreement') {
        const usp = new URLSearchParams(this.$route.query);
        return `${window.location.pathname}#/${document}?${usp}`;
      } else {
        return this.$contentTransposer(`footer.footerLinks.${document}.url`);
      }
    },
    getDocumentName(document) {
      return this.$contentTransposer(`footer.footerLinks.${document}.name`);
    },
    onCheckAcknowledge(isActive) {
      this.isAcknowledged = isActive;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/client/default/confirm-enrollment-modal.scss';
@import '@/assets/css/client/ingg/confirm-enrollment-modal.scss';
</style>
