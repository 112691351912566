<template>
  <component
    :is="variation"
    v-bind="propsAndAttrs"
    @input="handleInputEvent"
    @mds-date-picker-input-changed="handleInputChangedEvent"
  >
    <slot v-for="(_, name) in $slots" :slot="name" :name="name" />
  </component>
</template>
<script>
import Base from './Base.vue';

export default {
  name: 'MdsDatePicker',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    single: Base
  },
  inheritAttrs: false,
  props: {
    variation: {
      // We are keeping this prop internally for now in case more variations are added
      type: String,
      default: 'single',
      internal: true,
      docs: {
        validation: 'One of: `single`.',
        description: 'Sets the variation for the date picker.'
      }
    }
  },
  computed: {
    computedAttrs() {
      const attrs = {
        ...this.$attrs
      };

      // Suppress the native 'type' attribute to avoid unwanted design/behavior conflicts
      delete attrs.type;

      return attrs;
    },
    propsAndAttrs() {
      // Directly binding it in template does not work
      return { ...this.$props, ...this.computedAttrs };
    }
  },
  methods: {
    handleInputEvent(value) {
      this.$emit('input', value);
    },
    handleInputChangedEvent(value) {
      this.$emit('mds-date-picker-input-changed', value);
    }
  }
};
</script>

<style lang="scss">
@import './date-picker.scss';
</style>
